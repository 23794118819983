
.fab{
    margin:0px;
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    position: fixed;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.2);
    border: 0px solid rgba(0, 0, 0, 0.2);
    padding: 8px;
  }
.fab:hover{
  background-color: rgba(0, 0, 0, 0.3);
  bottom:22px;
}
.fab:focus{
  outline: none;
}

