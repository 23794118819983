.heartbeatOffline {
  margin-left:10px;
  margin-top:6px;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #afafaf;  
  box-shadow: 0 0 0 rgba(52, 150, 27, 0.4);  
}
.heartbeatOfflineAllDevices {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #afafaf;  
  box-shadow: 0 0 0 rgba(52, 150, 27, 0.4);  
}

.heartbeatOnline {
  margin-left:10px;
  margin-top:6px;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #2ccc39;  
  box-shadow: 0 0 0 rgba(52, 150, 27, 0.4);
  animation: heartbeatPulse 2s infinite;
}

.heartbeatOnlineAllDevices {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #2ccc39;  
  box-shadow: 0 0 0 rgba(52, 150, 27, 0.4);
  animation: heartbeatPulse 2s infinite;
}



@-webkit-keyframes heartbeatPulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(52, 150, 27, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(52, 150, 27, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(52, 150, 27, 0);
  }
}
@keyframes heartbeatPulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(52, 150, 27, 0.4);
    box-shadow: 0 0 0 0 rgba(52, 150, 27, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(52, 150, 27, 0);
      box-shadow: 0 0 0 10px rgba(52, 150, 27, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(52, 150, 27, 0);
      box-shadow: 0 0 0 0 rgba(52, 150, 27, 0);
  }
}

.heartbeatMapDevices {
  cursor: pointer;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #4B5563;  
  box-shadow: 0 0 0 rgba(17, 24, 39, 0.4);
  animation: heartbeatMapPulse 2s infinite;
}

@-webkit-keyframes heartbeatMapPulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(17, 24, 39, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(17, 24, 39, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(17, 24, 39, 0);
  }
}
@keyframes heartbeatMapPulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(17, 24, 39, 0.4);
    box-shadow: 0 0 0 0 rgba(17, 24, 39, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(17, 24, 39, 0);
      box-shadow: 0 0 0 10px rgba(17, 24, 39, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(17, 24, 39, 0);
      box-shadow: 0 0 0 0 rgba(17, 24, 39, 0);
  }
}


.alertHeartbeat {
  // display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ea580c;  
  box-shadow: 0 0 0 rgba(234, 88, 12, 0.4);
  animation: blink 1s infinite;
}



@-webkit-keyframes blink {
  0% {opacity: 0}
	49%{opacity: 0}
	50% {opacity: 1}
}

@keyframes blink {
  0% {opacity: 0}
	49%{opacity: 0}
	50% {opacity: 1}
}