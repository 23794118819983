// .heartbeatOffline {
//   margin-left:10px;
//   margin-top:6px;
//   display: block;
//   width: 12px;
//   height: 12px;
//   border-radius: 50%;
//   background: #afafaf;  
//   box-shadow: 0 0 0 rgba(52, 150, 27, 0.4);  
// }

.waitingOnData {
  // margin-left:10px;
  // margin-top:6px;
  display: block;
  padding: 12px;
  // width: auto;
  // height: auto;
  border: 1px solid #4f86ce;
  border-radius: 10pt;
  background: transparent;  
  box-shadow: 0 0 0 rgba(17, 54, 136, 0.4);
  animation: waitingPulse 2s infinite;
}

.disabledDevice {
  // margin-left:10px;
  // margin-top:6px;
  display: block;
  padding: 12px;
  // width: auto;
  // height: auto;
  border: 1px solid #757575;
  border-radius: 10pt;
  background: transparent;  
  box-shadow: 0 0 0 rgba(17, 54, 136, 0.4);
  // animation: waitingPulse 2s infinite;
}


@-webkit-keyframes waitingPulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(17, 54, 136, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 20px rgba(17, 54, 136, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(17, 54, 136, 0);
  }
}
@keyframes waitingPulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(17, 54, 136, 0.4);
    box-shadow: 0 0 0 0 rgba(17, 54, 136, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 20px rgba(17, 54, 136, 0);
      box-shadow: 0 0 0 20px rgba(17, 54, 136, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(17, 54, 136, 0);
      box-shadow: 0 0 0 0 rgba(17, 54, 136, 0);
  }
}

.clickAlertList {
  // margin-left:10px;
  // margin-top:6px;
  display: block;
  padding: 0px;
  width: auto;
  height: auto;
  border: 0px solid #4f86ce;
  border-radius: 25pt;
  background: transparent;  
  box-shadow: 0 0 0 rgba(17, 54, 136, 0.4);
  animation: clickAlertPulse 2s infinite;
}


@-webkit-keyframes clickAlertPulse {
  0% {
    // -webkit-box-shadow: 0 0 0 0 rgba(17, 54, 136, 0.4);
    background: transparent;
  }
  50% {
      // -webkit-box-shadow: 0 0 0 7px rgba(17, 54, 136, 0);
      background: #cedbec;  
  }
  100% {
      // -webkit-box-shadow: 0 0 0 0 rgba(17, 54, 136, 0);
      background: transparent;
  }
}
@keyframes clickAlertPulse {
  0% {
    background: transparent;
    // -moz-box-shadow: 0 0 0 0 rgba(17, 54, 136, 0.4);
    // box-shadow: 0 0 0 0 rgba(17, 54, 136, 0.4);
  }
  50% {
      // -moz-box-shadow: 0 0 0 7px rgba(17, 54, 136, 0);
      // box-shadow: 0 0 0 7px rgba(17, 54, 136, 0);
      background: #cedbec;  
  }
  100% {
      // -moz-box-shadow: 0 0 0 0 rgba(17, 54, 136, 0);
      // box-shadow: 0 0 0 0 rgba(17, 54, 136, 0);
      background: transparent;
  }
}