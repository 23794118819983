
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

/* :root {
  color: #565656;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.7;
}

body {
  background-color: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
} */

.wrapper {
  background-color: white;
  width: 400px;
  padding: 2rem;
  box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}

.inputs {
  width: 100%;
  height: 45px;
  /* padding: 0 1rem; */
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 1px solid #d4d5d6;
  /* color: #565656; */
  -webkit-appearance: none;
  background-color: transparent;
  border-radius: 5px;
  /* border:0px solid transparent; */
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.5rem 0.25rem;
  min-width: 100%;
  color: #4B5563;
}

.inputs:focus {
  border-color: cornflowerblue;
  outline: none;
}

.inputs.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  background-color: #3B82F6;
  color: #fff;
  font-weight: 900;
  letter-spacing: 0.1rem;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0.3rem 0.3rem;
}

.tag-item > .button {
  background-color: white;
  color: #3B82F6;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.asset-tag-item {
  background-color: transparent;
  display:'flex';
  border: 1px solid #3B82F6;
  color: #3B82F6;
  font-size: 500;
  letter-spacing: 0.1rem;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0.3rem 0.3rem;
}

.asset-tag-item > .button {
  background-color: white;
  color: #3B82F6;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
