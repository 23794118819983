.dropdown .alertChannelsButton {
	background-color:transparent;
	border-radius:24px;
	border:1px solid #4f86ce;
	display:inline-block;
	cursor:pointer;
	color:#4f87cc;
	font-family:Trebuchet MS;
	font-size:16px;
	// font-weight:bold;
	padding:5px 14px;
	text-decoration:none;
	text-shadow:0px 0px 0px #9eb9ff;
}
.dropdown .alertChannelsButton:hover {
	background-color:transparent;
}
.dropdown .alertChannelsButton:active {
	position:relative;
	top:1px;
}

.button .alertsSettingsButton {
	background-color:transparent;
	border-radius:24px;
	border:0px solid #4f86ce;
	display:inline-block;
	cursor:pointer;
	color:#4f87cc;
	font-family:Trebuchet MS;
	font-size:20px;
	// padding:19px 14px;
	text-decoration:none;
	text-shadow:0px 0px 0px #9eb9ff;
}
.button .alertsSettingsButton:hover {
	background-color:transparent;
}
.button .alertsSettingsButton:active {
	position:relative;
	top:1px;
}
.button .alertsSettingsButton::after {
    content: none;
}

.dropdown .organizations {
	background-color:transparent;
	width: 40%;
	border-radius:29px;
	border:1px solid #4f92cc;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Trebuchet MS;
	font-size:12px;
	font-weight:bold;
	padding:4px 16px;
	text-decoration:none;
	
	@media screen and (max-width: 950px) {
		// transform: scale(0.75);
		font-size:8px;
		padding:5px 15px;
		overflow: hidden;
	}
	@media screen and (max-width: 780px) {
	// transform: scale(0.75);
	font-size:7px;
	padding:5px 14px;
	overflow: hidden;

	}
	@media screen and (max-width: 680px) {
	// transform: scale(0.75);
	font-size:0px;
	padding:3px 5px 3px 5px;
	// height: 25px;
	// width: 5px;
	// display:block;
	// visibility:hidden;
	overflow: hidden;
	}	  
	
}

.dropdown .organizations:first-letter {
	@media screen and (max-width: 680px) {
		visibility:visible;
		font-size:15px;
		padding-left: '40px';

		}
	
}​,

.dropdown .organizations:hover {
	background-color:transparent;
}
.dropdown .organizations:active {
	position:relative;
	top:1px;
}

.sideNavBtnSelected {

	color:#4f87cc;
}

.form-control.organizationSelection {
    display: block;
    width: 100%;
    height: calc(1em + .5rem + 1px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 100;
	line-height: 1.5;
	font-family:Trebuchet MS;
    color: #9e9e9e;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #9e9e9e;
    // border-radius: '25px';
	// transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	
	@media screen and (max-width: 420px) {
		width: 150%;
	}
		  
	
}



.button .organizationsSettingsButton {
	background-color:transparent;
	border-radius:5px;
	border:0px solid #9e9e9e;
	display:inline-block;
	cursor:pointer;
	color:#9e9e9e;
	font-family:Trebuchet MS;
	// font-size:5px;
	// padding:19px 14px;
	text-decoration:none;
	text-shadow:0px 0px 0px #9e9e9e;
	outline: none;
}
.button .organizationsSettingsButton:hover {
	background-color:transparent;
}
.button .organizationsSettingsButton:active {
	position:relative;
	top:1px;
	outline: none;

}
.button .organizationsSettingsButton:focus {
	outline: none;

}
.button .organizationsSettingsButton::after {
    content: none;
}