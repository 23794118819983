/* width */
.cardScrollbar::-webkit-scrollbar {
	width: '2px';
  }
   
/* Track */
.cardScrollbar::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
/* Handle */
.cardScrollbar::-webkit-scrollbar-thumb {
	background-color:red;
	outline: 1px solid slategrey;
	border-radius: '25px';
  }



//   /* width */
// ::-webkit-scrollbar {
// 	width: 20px;
//   }
  
//   /* Track */
//   ::-webkit-scrollbar-track {
// 	box-shadow: inset 0 0 5px grey; 
// 	border-radius: 10px;
//   }
   
//   /* Handle */
//   ::-webkit-scrollbar-thumb {
// 	background: red; 
// 	border-radius: 10px;
//   }
  
//   /* Handle on hover */
//   ::-webkit-scrollbar-thumb:hover {
// 	background: #b30000; 
//   }